import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Collin Prusse | Developer', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hello, my name is ',
  name: 'Collin Prusse,',
  subtitle: 'a Full-Stack Web Developer',
  cta: 'See More',
};

// ABOUT DATA
export const aboutData = {
  img: 'myface.jpg',
  paragraphOne: `Eager to learn, enthusiastic Full-Stack developer proficient in MERN stack, HTML, CSS, and Python. I'm an great communicator and problem solver with a knack for quickly learning new technologies and libraries.`,
  paragraphTwo:
    'Please feel free to contact me with any questions about my work or any possible employment opportunities.',
  paragraphThree: ' ',
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'cherwell.jpg',
    title: 'Cherwell Software',
    info: `Currently a Junior Full-Stack Engineer with Cherwell Software. I work developing new features and design and create website content for the main company site, creating tools to train clients and inform potential customers on product offerings and services.`,
    info2: '',
    url: 'https://www.cherwell.com',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'expensify.jpg',
    title: 'Expensify',
    info:
      'Responsive expenses tracking application using React and Redux for the front-end, and Firebase for back-end storage and login authentication.',
    info2: '',
    url: 'https://expensify-app-react-28.herokuapp.com/',
    repo: 'https://github.com/cprus/expensify-app', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'rmmpersonal.jpg',
    title: 'RentMindMe',
    info:
      'Was part of a small development team creating a web-based application designed for property management, rent collection, and facilitating interactions between property owners and renters. Built using HTML, CSS/CSS Grid, React, Redux/Redux Saga, and Google Firebase.',
    info2: '',
    url: 'https://www.rentmindme.com/',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Want to work together? Awesome!',
  btn: 'Contact Me',
  email: 'collinprusse@mac.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://twitter.com/Collinalllcars',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/collin-prusse/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/cprus',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
